import React from 'react';

import Loader from '../Loader';
import './styles.scss';

const CardLoader = ({ message, withoutBackground }) => {
  return (
    <div className="cardLoaderContainer">
      <div className={`subContainer ${withoutBackground && 'transparentBackground'}`}>
        <Loader size="large" />
        {message && <div className="message">{message}</div>}
      </div>
    </div>
  );
};

export default CardLoader;
