import { onValue, off, update, child, remove } from 'firebase/database';

const listener = ({ callback }) => async snapshot => {
  const orders = snapshot.val();
  if (orders && typeof orders === 'object' && Object.keys(orders)) {
    callback(Object.keys(orders).map(orderId => orders[orderId]));
  }
};

export const processFailedOrders = async ({ orderRef, failedOrderRef, callback }) => {
  const orders = await new Promise(resolve => {
    onValue(failedOrderRef, snapshot => {
      off(failedOrderRef, 'value');
      resolve(snapshot.val());
    });
  });
  if (orders && typeof orders === 'object' && Object.keys(orders)) {
    removeListener({ ref: failedOrderRef });
    try {
      for (const orderId of Object.keys(orders)) {
        await update(child(orderRef, orderId), orders[orderId]);
        await remove(child(failedOrderRef, orderId));
      }
    } catch (err) {
      console.error(err);
    } finally {
      attachListener({ ref: failedOrderRef, callback });
    }
  }
};

export const attachListener = ({ ref, callback }) => {
  onValue(ref, listener({ callback }));
};

export const removeListener = ({ ref }) => {
  off(ref, 'value');
};
