import { GoogleAuthProvider, signOut, getAuth, signInWithCredential } from 'firebase/auth';

import firebase from '../../Config/FirebaseConfig';
import Api from '../Api';

let googleAuth;
let LOADING = false;

export const getLoading = () => LOADING;

export const setLoading = value => {
  LOADING = value;
};

export const getGoogleAuth = () => googleAuth;

export const setGoogleAuth = data => {
  googleAuth = data;
};

export const AUTH_PROVIDERS = {
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE'
};

export const COMPANY_META_KEY = 'COMPANY_META_KEY';

export const setCompanyMetaInLocalStorage = company =>
  localStorage.setItem(COMPANY_META_KEY, JSON.stringify(company));

export const getCompanyMetaFromLocalStorage = () =>
  JSON.parse(localStorage.getItem(COMPANY_META_KEY));

export const onLogout = async () => {
  LOADING = true;
  try {
    await signOut(getAuth(firebase));
    localStorage.clear();
    if (googleAuth) {
      googleAuth.signOut();
      googleAuth.disconnect();
    }
    LOADING = false;
  } catch (error) {
    LOADING = false;
  }
};

export const checkAndHandleUserAccessToWeb = (currentPlan = {}) => {
  const { features: { DESKTOP: { allowed } = {} } = {} } = currentPlan;
  return allowed;
};

export const googleSignInWithCustomToken = async (idToken, accessToken) => {
  try {
    const credential = GoogleAuthProvider.credential(idToken, accessToken);
    const result = await signInWithCredential(getAuth(firebase), credential);
    if (result.user) {
      const { company } = await Api.getCompanyAndUserObject();
      if (!company.currencyCode) {
        throw new Error('User not allowed');
      }
      const currentPlan = await Api.getCurrentPlan();
      const allowed = checkAndHandleUserAccessToWeb(currentPlan);
      if (!allowed) {
        throw new Error('User Not Allowed');
      }
      setCompanyMetaInLocalStorage(company);
      return;
    } else {
      throw new Error('No user found');
    }
  } catch (error) {
    onLogout();
    throw error;
  }
};

export const loadGoogleScript = () => {
  //loads the Google JavaScript Library
  (function() {
    const id = 'google-js';
    const src = 'https://apis.google.com/js/platform.js';

    //we have at least one script (React)
    const firstJs = document.getElementsByTagName('script')[0];

    //prevent script from loading twice
    if (document.getElementById(id)) {
      return;
    }
    const js = document.createElement('script');
    js.id = id;
    js.src = src;
    js.onload = window.onGoogleScriptLoad;
    firstJs.parentNode.insertBefore(js, firstJs);
  })();
};
