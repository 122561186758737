import firebase from '../../Config/FirebaseConfig';
import { ref, getDatabase } from 'firebase/database';
import * as failedOrderListener from './FailedOrderListener';
import * as orderListener from './OrderListener';

class OrderListener {
  constructor({ companyId, failedOrderCallback }) {
    this.failedOrderCallback = failedOrderCallback;
    this.refKey = `company-orders/${companyId}`;
    this.orderRef = ref(getDatabase(firebase), `${this.refKey}/orders`);
    this.failedOrderRef = ref(getDatabase(firebase), `${this.refKey}/failed-orders`);
  }

  processFailedOrders = () =>
    failedOrderListener.processFailedOrders({
      orderRef: this.orderRef,
      failedOrderRef: this.failedOrderRef,
      callback: this.failedOrderCallback
    });

  attachListeners = () => {
    orderListener.attachListener({ orderRef: this.orderRef, failedOrderRef: this.failedOrderRef });
    failedOrderListener.attachListener({
      ref: this.failedOrderRef,
      callback: this.failedOrderCallback
    });
  };

  removeListeners = () => {
    orderListener.removeListener({ ref: this.orderRef });
    failedOrderListener.removeListener({ ref: this.failedOrderRef });
  };
}

export default OrderListener;
