import React from 'react';

import Logo from '../../../../Assets/Media/logo.png';
import GoogleLogo from '../../../../Assets/Media/google.png';
import './styles.scss';

const LoginContent = ({ handleLogin }) => {
  return (
    <div className="loginContentContainer">
      <div className="loginContentContainer loginContentBody">
        <img src={Logo} className="logo" alt="" />
        <h1>QuickSell</h1>
      </div>
      <div className="loginContentContainer loginCardFooter">
        <button onClick={() => handleLogin()} className="btn googleBtn">
          <div className="logoContainer googleLogoContainer">
            <img src={GoogleLogo} alt="" />
          </div>
          <span className="text">Sign in with Google</span>
        </button>
      </div>
    </div>
  );
};

export default LoginContent;
