import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import {
  googleSignInWithCustomToken,
  loadGoogleScript,
  setGoogleAuth
} from '../../../Services/DataManager/Authentication';
import LoginBanner from './LoginBanner';
import LoginContent from './LoginContent';
import CardLoader from '../../Common/CardLoader';

class Login extends Component {
  static propTypes = {
    setAuthenticated: PropTypes.func
  };
  state = {
    showLoader: false,
    gapi: null,
    googleAuth: null
  };

  componentDidMount() {
    if (window.gapi) {
      this.loadGoogleAuth();
      return;
    }
    window.onGoogleScriptLoad = () => {
      this.loadGoogleAuth();
    };
    loadGoogleScript();
  }

  loadGoogleAuth = () => {
    window.gapi.load('auth2', () => {
      (async () => {
        const _googleAuth = await window.gapi.auth2.init({
          apiKey: 'AIzaSyD1U5aoVoFVxs2GhJ7Q0mpHwL25fJwks2U',
          clientId: '223646330354-n60bgmrlna5dc1h4tjtosqu4n4v1m1tq.apps.googleusercontent.com',
          discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
          redirect_uri: 'storagerelay://https/tally.dev.quicksell.co?id=auth742850',
          scope: 'profile email',
          prompt: 'select_account'
        });
        setGoogleAuth(_googleAuth);
        this.setState({
          gapi: window.gapi,
          googleAuth: _googleAuth
        });
      })();
    });
  };

  handleLogin = async () => {
    const { googleAuth } = this.state;
    try {
      this.setState({ showLoader: true });
      if (!googleAuth) {
        throw new Error('No google scipt loaded');
      }
      const result = await googleAuth.signIn();
      if (!result.getAuthResponse().id_token) {
        throw new Error('No token found');
      }
      await googleSignInWithCustomToken(
        result.getAuthResponse().id_token,
        result.getAuthResponse().access_token
      );
      this.setState({ showLoader: false });
      this.props.setAuthenticated(true);
    } catch (error) {
      this.setState({ showLoader: false });
    }
  };

  renderLoader = () => {
    const { showLoader } = this.state;
    if (!showLoader) {
      return null;
    }
    return <CardLoader message={'Signing in…'} />;
  };

  render() {
    return (
      <div className="login">
        <LoginBanner />
        <div className="loginCard">
          {this.renderLoader()}
          <LoginContent handleLogin={this.handleLogin} />
        </div>
      </div>
    );
  }
}

export default Login;
