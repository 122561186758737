import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import firebase from '../../Config/FirebaseConfig';
import Api from '../../Services/Api';
import {
  checkAndHandleUserAccessToWeb,
  getLoading,
  onLogout,
  setCompanyMetaInLocalStorage
} from '../../Services/DataManager/Authentication';

import Dashboard from '../Dashboard';
import CardLoader from '../Common/CardLoader';
import LoginScreen from '../Authentication/LoginScreen';
import './styles.scss';

const Navigation = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authenticated || getLoading()) {
      return;
    }
    const auth = getAuth(firebase);
    onAuthStateChanged(auth, authUser => {
      if (authUser && !authenticated) {
        const remoteDataPromises = [Api.getCompanyAndUserObject(), Api.getCurrentPlan()];
        Promise.all(remoteDataPromises)
          .then(remoteDataList => {
            const { company } = remoteDataList[0];
            if (!company || (company && !company.currencyCode)) {
              throw new Error('User not allowed');
            }
            const currentPlan = remoteDataList[1];
            const allowed = checkAndHandleUserAccessToWeb(currentPlan);
            if (!allowed) {
              throw new Error('User not allowed');
            }
            setCompanyMetaInLocalStorage(company);
            setLoading(false);
            setAuthenticated(true);
          })
          .catch(error => {
            setLoading(false);
            onLogout();
          });
      } else {
        setLoading(false);
      }
    });
  }, [authenticated]);

  const renderRoutes = () => {
    if (!authenticated) {
      return (
        <>
          <Route path={'/login'} element={<LoginScreen setAuthenticated={setAuthenticated} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      );
    }
    return (
      <>
        <Route path="/dashboard" element={<Dashboard setAuthenticated={setAuthenticated} />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </>
    );
  };

  return (
    <div className="appWrapper">
      <div className="screenWrapper">
        {loading ? (
          <CardLoader withoutBackground />
        ) : (
          <Router>
            <Routes>{renderRoutes()}</Routes>
          </Router>
        )}
      </div>
    </div>
  );
};

export default Navigation;
