import { initializeApp } from 'firebase/app';

import AppConfig from './AppConfig';

let config;
if (AppConfig.env !== 'development') {
  config = {
    apiKey: 'AIzaSyABzfqrEHkJkaSTooHh2EJ7BSmdp1VCxvM',
    authDomain: 'project-7804444097795271748.firebaseapp.com',
    databaseURL: 'https://qs-external-stage.firebaseio.com',
    projectId: 'project-7804444097795271748',
    storageBucket: 'project-7804444097795271748.appspot.com',
    messagingSenderId: '1031733541585'
  };
} else {
  config = {
    apiKey: 'AIzaSyD1U5aoVoFVxs2GhJ7Q0mpHwL25fJwks2U',
    authDomain: 'sx-app-a04c1.firebaseapp.com',
    databaseURL: 'https://qs-general-db.firebaseio.com',
    projectId: 'sx-app-a04c1',
    storageBucket: 'sx-app-a04c1.appspot.com',
    messagingSenderId: '223646330354'
  };
}
const firebase = initializeApp(config);

export default firebase;
