import request from './Request.js';
import Config from '../Config/AppConfig';

const Api = {
  getCurrentPlan: () =>
    request.authGet(`${Config.qsApiEndPoint}/v3/company/plan-features?storeType=DESKTOP`),
  login: auth => request.authPost(`${Config.qsApiEndPoint}/v1/auth/login`, auth),
  getCompanyAndUserObject: () =>
    request.authGet(`${Config.qsApiEndPoint}/v1/company/company-user-object`)
};

export default Api;
